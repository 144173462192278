/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "core-js/stable";
import "regenerator-runtime/runtime";

$(document).ready(function() {
	$(window).unload(saveSettings);
	loadSettings();

	autoReload();
	toggleColumn();
	totals();
});

function totals() {
	if ($("#totals").length > 0) {
		$("#totals i").click(function() {
			if ($(this).hasClass("fa-plus-circle")) {
				$("tr.total").show();
				$(this).removeClass("fa-plus-circle");
				$(this).addClass("fa-minus-circle");
			} else {
				$("tr.total").hide();
				$(this).removeClass("fa-minus-circle");
				$(this).addClass("fa-plus-circle");
			}
		});
	}
}

function toggleColumn() {
	if ($("#toggle-column").length > 0) {
		$("#toggle-column li").click(function() {
			let index = $(this).index();
			if (index >= 0) {
				let column = index + 1;
				if (
					$(this)
						.find(":checkbox")
						.is(":checked")
				) {
					$("th:nth-child(" + column + "), tr td:nth-child(" + column + ")").show();
				} else {
					$("th:nth-child(" + column + "), tr td:nth-child(" + column + ")").hide();
				}
			}
		});
	}
}

function loadSettings() {
	loadToggle();
}

function saveSettings() {
	saveToggle();
}

function loadToggle() {
	if ($("#toggle-column").length > 0 && localStorage.toggle) {
		let toggle = JSON.parse(localStorage.toggle);
		if (toggle && toggle[window.location.pathname]) {
			toggle[window.location.pathname].forEach(function(x, i) {
				let column = i + 1;
				$("#toggle-column li :checkbox:eq(" + i + ")").prop("checked", x);
				if (x) {
					$("th:nth-child(" + column + "), tr td:nth-child(" + column + ")").show();
				} else {
					$("th:nth-child(" + column + "), tr td:nth-child(" + column + ")").hide();
				}
			});
		}
	} else if ($("#toggle-column li").length > 0) {
		$("#toggle-column li").each(function(index) {
			if (index >= 0) {
				let column = index + 1;
				if (
					!$(this)
						.find(":checkbox")
						.is(":checked")
				) {
					$("th:nth-child(" + column + "), tr td:nth-child(" + column + ")").hide();
				}
			}
		});
	}
}

function saveToggle() {
	if ($("#toggle-column").length > 0) {
		let toggle = {};
		if (localStorage.toggle) {
			toggle = JSON.parse(localStorage.toggle);
		}
		localStorage.toggle = JSON.stringify({
			...toggle,
			[window.location.pathname]: $("#toggle-column li")
				.map(function() {
					return $(this)
						.find(":checkbox")
						.is(":checked");
				})
				.get(),
		});
	}
}

function autoReload() {
	if ($("#auto-reload").length > 0) {
		// 120 secondes
		window.setInterval(updateElement, 120000);
	}
}

var updateRequest = null;

function updateElement() {
	if (updateRequest) updateRequest.abort();
	updateRequest = $.get(window.location.pathname + window.location.search, function(response) {
		let source = $(response);
		if (source.find("#auto-reload").length > 0) {
			$("#auto-reload").html(source.find("#auto-reload").html());
			loadSettings();
		}
	});
}
